import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

export const query = graphql`
  query {
    site {
      siteMetadata {
        description
      }
    }
  }
`
const IndexPage = ({data}) => {

  return (
    <Layout>
      <SEO title="Home" />
      <p>{data.site.siteMetadata.description}</p>
      <p>The site is currently under construction. Please come back in a couple days for more information.</p>
      <p>5-25-2020</p>
      {/* <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
        <Image />
      </div> */}
  </Layout>
  )
}

export default IndexPage
